import useError404Settings from "@municipio/gatsby-theme-basic/src/hooks/error404";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import React from "react";

export default function NotFoundPage() {
  const { processPageContent } = useHTMLProcessor();
  const { errorDescription, errorSummary, errorTitle } = useError404Settings();
  let { content: processedContent } = processPageContent(errorDescription);

  return (
    <article className="c-article o-margin-top-large">
      <div className="o-grid">
        <div className="o-grid-row">
          <div className="o-grid-block o-grid-block--inherit">
            <h1 className="c-article__title">{errorTitle}</h1>
          </div>
        </div>
        <div className="o-grid-row">
          <div className="o-grid-block o-grid-block--inherit">
            <div className="preamble">{errorSummary}</div>
          </div>
        </div>
        <div className="o-grid-row">
          <div className="o-grid-block o-grid-block--inherit c-article">
            {processedContent}
          </div>
        </div>
      </div>
    </article>
  );
}
