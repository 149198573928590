import "./src/index.css";

export const onRouteUpdate = () => {
  if (typeof window !== "undefined") {
    const mainElement = document.getElementById("main");
    if (mainElement) {
      mainElement.scrollTo(0, 0); // Reset scroll position for the #main container
    }
  }
};
